@import "src/assets/styles/colors";

.top-features {
  font-family: 'Kumbh Sans', sans-serif;

  h2 {
    font-size: 2.5rem;
  }

  .top-features-icon {
    fill: #FFFFFF;
    transition: .2s ease-in-out fill;
    height: 40%;
  }

  .top-features-column {
    margin-bottom: 20px;
  }

  .custom-card {
    background: #131313;
    color: #FFFFFF;
    width: 100%;
    border-radius: 4px;

    &:hover {
      .top-features-icon {
        fill: $primary-color;
      }
    }
  }

  .card-custom-top-content {
    width: 100%;
    height: 100%;
  }

  .custom-card-body {
    padding: 0 1.25rem 1.25rem;
  }

  @media (max-width: 768px) {
    .game-card-column {
      margin-top: 1rem;
    }
  }

}
