@import "src/assets/styles/colors";

.home-about-us {
  font-family: 'Kumbh Sans', sans-serif;

  img {
    float: right;
  }

  h2 {
    font-size: 2.5rem;
  }

  @media (max-width: 1400px) {
    img {
      max-width: 100%;
    }
  }
  
  @media (max-width: 991px) {
    img {
      display: none;
    }
  }
}
