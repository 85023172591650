.blog-card {
  font-family: 'Kumbh Sans', sans-serif;
  text-decoration: none;

  .image {
    width: 100%;
    height: 100%;
    background: no-repeat center center;
    background-size: cover;
  }

  .custom-btn {
    color: #000000;
    font-size: 17px;
    background-color: #FFD768;
    padding: 0.8rem 1.8rem;

    &:hover, &:focus {
      background-color: #FFD768;
    }
  }

  h4 {
    color: #FFFFFF;
  }

}
