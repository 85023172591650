.home-banner {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }

  h1 {
    top: 50%;
    left: 50%;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    font-family: 'Bebas Neue', sans-serif;
  }

  @media (max-width: 410px) {
    h1 {
      font-size: 2rem;
    }
  }
}

