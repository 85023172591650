footer {

  .content {
    color: #FFFFFF;
    padding: 20px 0;
    border-top: 1px solid #D9D9D9;
  }

  img {
    height: 45px;
  }
}
