.game-details-page {
  color: #FFFFFF;
  font-family: 'Kumbh Sans', sans-serif;

  .game-frame {
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  table {
    td {
      padding: 6px 16px;
      border: 1px solid white;
    }
  }

  .game-bar {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    background: #131313;

    img {
      width: 16px;
      cursor: pointer;
      user-select: none;
    }
  }

  h2 {
    font-size: 2.5rem;
  }

  .section {
    margin-top: 7rem;

    @media (max-width: 1024px) {
      margin-top: 6rem;
    }
  }
}
