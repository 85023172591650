.ratio-component {
  width: 100%;
  padding-top: 50%;
  position: relative;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
