@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kumbh+Sans&display=swap');

html, body {
  background: #000000;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media (min-width: 768px) {
  .game-card-column {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .d-xs-none {
    display: none;
  }
}
