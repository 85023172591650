.game-card {
  font-family: 'Kumbh Sans', sans-serif;
  color: #FFFFFF;
  display: block;
  text-decoration: none;

  p {
    color: #D2D3D5;
  }

  .image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image {
    width: 100%;
    height: 100%;
    background: no-repeat center center/cover;
    border-radius: 4px;
    transition: .3s ease-in-out;
  }

  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 586px) {
      font-size: 1rem;
    }
  }

  &:hover {
    .image {
      transform: scale(1.06);
    }
  }
}
