.contact-us {
  color: #FFFFFF;
  font-family: 'Kumbh Sans', sans-serif;

  @media (min-width: 992px) {
    h2 {
      font-size: 3rem;
    }
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 2.5rem;
    }

    .contact-us-icon {
      width: 40px;
    }
  }

  .input {
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
      margin-top: 1rem;
    }

    img {
      position: absolute;
      top: 12px;
    }

    input {
      background: transparent;
      outline: none;
      border: none;
      border-bottom: 1px solid #3D3C3E;
      width: 100%;
      color: #FFFFFF;
      padding: 10px 0 10px 30px;
      position: relative;

      &:hover ~ .line, &:focus ~ .line {
        width: 100%;
      }

      &.error {
        border-color: #cc0000;
      }
    }

    textarea {
      background: transparent;
      outline: none;
      border: none;
      border-bottom: 1px solid #3D3C3E;
      width: 100%;
      color: #FFFFFF;
      padding: 10px 0 10px 30px;
      position: relative;

      &:hover ~ .line, &:focus ~ .line {
        width: 100%;
      }

      &.error {
        border-color: #cc0000;
      }
    }

    .line {
      position: absolute;
      content: '';
      width: 0;
      height: 1px;
      background: #FFFFFF;
      bottom: 1px;
      left: 0;
      transform: translateY(100%);
      transition: 250ms ease-in-out;

      &.textarea {
        transform: translateY(0);
        bottom: 6px;
      }
    }
  }

  .custom-btn {
    color: #000000;
    font-size: 17px;
    background-color: #FFD768;
    padding: 0.8rem 1.8rem;

    &:hover, &:focus {
      background-color: #FFD768;
    }

    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }

  .custom-success-alert {
    color: #52FF78;
    border-color: #52FF78;
    background: transparent;
  }

  .custom-error-alert {
    color: #cc0000;
    border-color: #cc0000;
    background: transparent;
  }
}
