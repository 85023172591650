.home-page {

  .section {
    margin-top: 8rem;

    @media (max-width: 1024px) {
      margin-top: 6rem;
    }
  }

}
