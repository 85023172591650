$header-height: 116px;

.header {

  .header-hidden-element {
    height: $header-height;
  }

  header {
    .navbar .navbar-nav .nav-link {
      color: white;
      font-size: 20px;
      position: relative;

      &.active::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        width: 15px;
        height: 4px;
        background: #FFD768;
        transform: translateX(-50%);
        border-radius: 8px;
      }
    }

    .custom-btn {
      color: #000000;
      background-color: #FFD768;

      &:hover, &:focus {
        background-color: #FFD768;
      }
    }
  }

  @media (max-width: 991px) {
    header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background: #000;
      width: 100%;
    }

    .mobile-hidden {
      display: none !important;
    }
  }

  @media (min-width: 992px) {
    .desktop-hidden {
      display: none !important;
    }
  }

  .sidebar {
    height: calc(100vh - $header-height);
    position: fixed;
    left: 0;
    top: $header-height;
    width: 100%;
    background: #00000050;
    z-index: 10;
    transition: .2s ease-in-out;
    opacity: 0;
    visibility: hidden;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .content {
      width: 40%;
      height: 100%;
      background: #000000;
      transition: .2s ease-in-out;
      transform: translateX(-100%);
      max-width: 300px;

      a {
        color: #FFFFFF;
        font-size: 1.3rem;
        text-decoration: none;
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;

      .content {
        transform: translateX(0);
      }
    }
  }

}
