.banner-component {
  position: relative;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-family: 'Bebas Neue', sans-serif;
  }

  img {
    width: 100%;
  }
}
