@import "src/assets/styles/colors";

.top-games {
  font-family: 'Kumbh Sans', sans-serif;

  h2 {
    font-size: 2.5rem;
  }

  .view-all-btn {
    cursor: pointer;
    color: $primary-color;
    text-decoration: none;
  }
}
